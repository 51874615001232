<template>
    <div class="bill">
        <van-dropdown-menu>
            <van-dropdown-item v-model="value1" :options="option1"  @change="resetData"/>
            <van-dropdown-item :title="inOutNum" disabled></van-dropdown-item>
        </van-dropdown-menu>
        <van-cell-group>
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="loadData" >
                <van-cell center v-for="(item, index) in list" :key="index" :title="item.inLiangpiaoNum?item.amountType:item.amountType+ '（'+item.amountStatus+'）'" :label="item.createTime">
                    <template #title>
                        <span v-if="item.inLiangpiaoNum">{{item.amountType}}</span>
                        <span v-else>{{item.amountType}}<span style="font-size: 12px;color: #aaa">（{{item.amountStatus}}）</span></span>
                    </template>
                    <template #icon>
                        <img v-if="item.inLiangpiaoNum" src="../../assets/img/qianliang/bill/green.png" style="margin-right: 6px;height: 35px;width: 35px;">
                        <img v-else src="../../assets/img/qianliang/bill/red.png" style="margin-right: 6px;height: 35px;width: 35px;">
                    </template>
                    <div v-if="item.inLiangpiaoNum" style="color: #67C23A">+ {{item.inLiangpiaoNum}}</div>
                    <div v-else style="color: #FF0000">- {{item.outLiangpiaoNum}}</div>
                </van-cell>
            </van-list>
        </van-cell-group>
    </div>
</template>

<script>
export default {
    name: "bill",
    data() {
        return {
            value1: '',
            option1: [
                // { text: '2022年8月', value: 0 },
                // { text: '2022年9月', value: 1 },
                // { text: '2022年10月', value: 2 },
            ],

            loading:false,
            finished:false,
            current: 0,  // 当前页
            totalPage: 0,  // 总页数
            totalCount: 0,  // 总条数

            inOutNum:'',  // 收入支出信息
            list:[]
        };
    },
    created() {
        if(!localStorage.getItem('xToken') || !localStorage.getItem('userInfo')){
            this.$router.replace('/verify')
        }
    },
    mounted() {
        // 重置页数
        this.resetData();
    },
    methods:{
        resetData() {
            this.current = 0;
            this.totalPage = 0;
            this.totalCount = 0;
            this.loading = false;
            this.finished = false;
            this.list = [];
        },
        loadData(){
            let that = this;
            this.current++;

            // 加载月份
            if(!this.value1){
                this.option1 = this.getMonths(12)
                this.value1 = this.option1[0].value
            }
            // 查询数据
            this.post('/member/account/queryPageList',{
                createMonth: this.value1,
                current: this.current,
                size: 20,
            }).then(result => {
                that.inOutNum = '收入黑钻：' + result.data.inLiangpiaoNum +  '   支出：' + result.data.outLiangpiaoNum;
                for(let i = 0; i < result.data.result.length; i++){
                    that.list.push(result.data.result[i])
                }
                that.totalPage = result.data.totalPage
                that.totalCount = result.data.totalCount

                that.loading = false;
                console.log(this.current)
                console.log(this.totalPage)
                if(this.current >= this.totalPage){
                    this.finished= true;
                }

            })
        },
        getMonths(n){
            //返回半年或者一年的月份
            let nowDate = new Date()
            let array = []
            let year = nowDate.getFullYear()
            let mon = nowDate.getMonth() + 1
            for (let i = 0; i < n; i++) {
                if (mon < 10) {
                    mon = '0' + mon
                }
                array[i] = {
                    text: year + '年' + mon + '月',
                    value: year + '-' + mon
                }
                // 最早查询到2023年1月
                if(year == 2023 && mon == 1){
                    return array
                }
                mon = mon - 1
                if (mon <= 0) {
                    year = year - 1
                    mon = mon + 12
                }
            }
            return array
        },
    }
}
</script>

<style>
.bill .van-dropdown-menu__bar .van-dropdown-menu__item:first-child{
    flex: 0 0 auto;
}
.bill .van-dropdown-menu__bar .van-dropdown-menu__item:nth-child(2) .van-dropdown-menu__title::after{
    content: none;
}
</style>
